<template>
  <div class="page-content">
    <breadcrumb title="Einstellungen" :goBack="true" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-8 col-md-4 col-lg-3 mx-auto">
          <form class="edit edit-form" action="#!">
            <div class="form-group">
              <label class="col-4">Dein Username:</label>
              <input disabled type="text" class="form-control" :placeholder="userName">
            </div>
            <div class="form-group">
              <label class="col-4">Deine Email:</label>
              <input disabled type="email" class="form-control" :placeholder="userMail">
            </div>

            <router-link class="btn btn-block btn-block__lg-half mt-2" to="/change-password">Passwort ändern</router-link>
            <button @click.prevent="logOut" class="btn btn-block btn-block__lg-half btn-active mt-4">Abmelden</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'Profile',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
    },
    computed:{
    userName(){
      return this.$store.state.user.name;
    },
    userMail(){
      return this.$store.state.user.email;
    },
  },
  methods: {
    showLoader,
    hideLoader,
    logOut(){
      this.loader = this.showLoader(this.loader);
      this.$store.dispatch('logout');

      this.$notify({
        duration: 3500,
        title: 'Erfolgreich abgemeldet!',
        text: 'Sie haben sich vom Tripmeister abgemeldet.'
      });

      this.loader = this.hideLoader(this.loader);
      this.$router.push({name:'Home'});
    },
  }
}
</script>
